import React, { useEffect, useState } from 'react';
import './LatestBlogPosts.css'; // Create a separate CSS file for styling
const blogUrlRoot = "https://brickprices.co.uk/article"; // Base URL for blog posts
const LatestBlogPosts = () => {
  const [posts, setPosts] = useState([]);

  // Fetch posts from WordPress API
  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await fetch(
          'https://brickprices.co.uk/blog/wp-json/wp/v2/posts?per_page=3&_embed'
        ); // Change the URL to your WordPress API
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    }
    fetchPosts();
  }, []);

  return (
    <div className="latest-blog-posts">
      {posts.map((post) => (
        <div key={post.id} className="post-item">
          {/* Display the featured image */}
          <div className="post-image">
            {post._embedded && post._embedded['wp:featuredmedia'] && (
              <img
                src={post._embedded['wp:featuredmedia'][0].source_url}
                alt={post.title.rendered}
              />
            )}
          </div>

          {/* Vertical Separator */}
          <div className="vertical-separator"></div>

          {/* Display the title and excerpt */}
          <div className="post-content">
            <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
            <p>
              {post.excerpt.rendered
                .replace(/(<([^>]+)>)/gi, '') // Remove HTML tags from the excerpt
                .split(' ')
                .slice(0, 10)
                .join(' ') + '...'}
            </p>
            <a href={`${blogUrlRoot}/${post.slug}`} className="read-more-link">
              Read more
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LatestBlogPosts;
