import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function SearchBar({ setResults, updateInput, updateDropDownVisible, input, onFocus, onBlur, isFocused }) {
    const navigate = useNavigate();
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const fetchData = (value) => {
        if (!value) {
            setResults([]);
            return;
        }

        const url = `${process.env.REACT_APP_API_URL}/inputsearch/${value}`;

        fetch(url)
            .then((response) => response.json())
            .then((json) => {
                //const results = json.filter((lego) => lego.name && lego.name.toLowerCase().includes(value.toLowerCase()));
                const results = json.filter((lego) => lego.name);
                results.unshift({
                    id: "search",
                    name: `Search All ${value}`,
                });
                setResults(results);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setResults([]);
            });
    };

    const handleChange = (value) => {
        updateInput(value);
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        if (value.length >= 4) {
            updateDropDownVisible(true);
            setDebounceTimeout(setTimeout(() => fetchData(value), 300)); // Debounce with 300ms delay
        } else {
            updateDropDownVisible(false);
            setResults([]);
        }
    };

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            navigate(`/search/${input}`);
        }
    };

    const clearText = () => {
        updateInput('');
        setResults([]);
        updateDropDownVisible(false);
    };

    const handleFocus = () => {
        onFocus(); // Propagate focus event
    };

    const handleBlur = () => {
        onBlur(); // Propagate blur event
    };

    return (
        <div className="search-container">
            <input
                type="text"
                id="search"
                name="search"
                className={`search-box ${isFocused ? 'focused' : ''}`}
                value={input}
                onChange={(e) => handleChange(e.target.value)}
                onKeyUp={handleKeyUp}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Search for LEGO sets, or enter a Lego ID"
            />
            {input && (
                <button onClick={clearText} className="clear-text">
                    &#x2715; {/* Unicode character for 'X' */}
                </button>
            )}
        </div>
    );
}
