import React, { useEffect, useState } from 'react';
import ProductCarousel from '../components/ProductCarousel';
import LatestBlogPosts from '../components/LatestBlogPosts'; 

import { Helmet } from 'react-helmet';
import './Home.css';

const App = () => {
  const [popularProducts, setPopularProducts] = useState([]);
  const [retiringProducts, setRetiringProducts] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);
  const [dailyDiscounts, setDailyDiscounts] = useState([]);
  const [weeklyDiscounts, setWeeklyDiscounts] = useState([]);
  const [monthlyDiscounts, setMonthlyDiscounts] = useState([]);
  const [rareProducts, setRareProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const popularProductsUrl = `${process.env.REACT_APP_API_URL}/home/popular`;
  const retiringProductsUrl = `${process.env.REACT_APP_API_URL}/home/retiring`;
  const latestProductsUrl = `${process.env.REACT_APP_API_URL}/home/latest`;
  const dailyDiscountsUrl = `${process.env.REACT_APP_API_URL}/home/daily`;
  const weeklyDiscountsUrl = `${process.env.REACT_APP_API_URL}/home/weekly`;
  const monthlyDiscountsUrl = `${process.env.REACT_APP_API_URL}/home/monthly`;
  const rareProductsUrl = `${process.env.REACT_APP_API_URL}/home/rare`;
  const siteName = process.env.REACT_APP_SITE_NAME;

  const fetchData = async (url, setState) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Unable to fetch data');
      }
      const data = await response.json();
      setState(data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchData(popularProductsUrl, setPopularProducts),
          fetchData(retiringProductsUrl, setRetiringProducts),
          fetchData(latestProductsUrl, setLatestProducts),
          fetchData(rareProductsUrl, setRareProducts),
          fetchData(dailyDiscountsUrl, setDailyDiscounts),
          fetchData(weeklyDiscountsUrl, setWeeklyDiscounts),
          fetchData(monthlyDiscountsUrl, setMonthlyDiscounts)
        ]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [popularProductsUrl, latestProductsUrl,rareProductsUrl,dailyDiscountsUrl,weeklyDiscountsUrl,monthlyDiscountsUrl, retiringProductsUrl]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <Helmet>
        <title>{siteName} - Lego Set Price Comparison</title>
        <meta name="description" content="BrickSave compares prices of hundreds of LEGO Sets across the UK. If you are looking for the cheapest prices for LEGO Sets, then look no further!" />
        <meta name="keywords" content="Lego, Lego Set Prices, Compare Lego Prices, Search Lego, Lego Price Comparison, New Lego Sets, Cheap Lego Sets" />
      </Helmet>

      <div className="home-featured">
        <div className="home-featured-left">
          <a href="/browse/Harry%20Potter">
            <img src="/assets/images/harry-potter-lego-wide.jpeg" alt="Lego Harry Potter Price Comparison" className="large-image" />
          </a>
          <div className="small-images">
            <a href="/browse/Marvel">
              <img src="/assets/images/lego-marvel.jpeg" alt="Lego Marvel Price Comparison" className="small-image" />
            </a>
            <a href="/browse/DC">
              <img src="/assets/images/lego-dc-superheroes.jpeg" alt="Lego DC Superheroes Price Comparison" className="small-image" />
            </a>
          </div>
        </div>
        <div className="home-featured-right">
        <LatestBlogPosts /> {/* This will display the last 4 blog posts */}
        </div>
      </div>

      <div className="home-container">

        <div className="home-menu">
          <ul>
            <li><a href = "/browse/City">Lego City</a></li>
            <li><a href = "/browse/Architecture">Lego Architecture</a></li>
            <li><a href = "/browse/Duplo">DUPLO</a></li>
            <li><a href = "/browse/Speed Champions">Speed Champions</a></li>
            <li><a href = "/browse/Creator">Creator</a></li>
          </ul>
        </div>
          <p className="home-hot">
          <a target="_blank" href="https://www.amazon.co.uk/gp/bestsellers/kids/364074031?ref_=Oct_d_obs_S&pd_rd_w=aCaxE&content-id=amzn1.sym.f0948994-11a8-46fa-88b5-ec28433ee61c&pf_rd_p=f0948994-11a8-46fa-88b5-ec28433ee61c&pf_rd_r=7H2TX5M2AG1V6NFK1CY9&pd_rd_wg=kCgYt&pd_rd_r=2c81c1c2-32d5-446f-b30d-5966a80a30c1&tag=dvdbargainale-21"> Hottest Lego Sets Right Now</a>
        </p>
        
        <div className="home-header-container">
            <p className="home-header">Popular Right Now</p>
            <p className="home-header-link"><a href="/browse/popular">See all Popular Lego Sets</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={popularProducts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Daily Deals</p>
          <p className="home-header-link"><a href="/browse/daily">See all Daily Deals</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={dailyDiscounts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Weekly Deals</p>
          <p className="home-header-link"><a href="/browse/weekly">See all Weekly Deals</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={weeklyDiscounts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Monthly Deals</p>
          <p className="home-header-link"><a href="/browse/monthly">See all Monthly Deals</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={monthlyDiscounts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Newest Lego Sets</p>
          <p className="home-header-link"><a href="/browse/latest">See all Newest Lego Sets</a></p>
        </div>
        
        <div className="carousel">
          <ProductCarousel products={latestProducts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Retiring Lego</p>
          <p className="home-header-link"><a href="/browse/retiring">See all retiring Lego Set</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={retiringProducts} />
        </div>
        <div className="home-header-container">
          <p className="home-header">Hard to Find Lego</p>
          <p className="home-header-link"><a href="/browse/rare">See the Rarest Modern Lego</a></p>
        </div>
        <div className="carousel">
          <ProductCarousel products={rareProducts} />
        </div>

      </div>
    </>
  );
};

export default App;
