import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import './Articles.css'; // Update this file with new styles

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const siteName = `${process.env.REACT_APP_SITE_NAME}`;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Fetch the most recent posts from the WordPress REST API
        const response = await fetch('https://brickprices.co.uk/blog/wp-json/wp/v2/posts?_embed');
        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }
        const data = await response.json();
        setArticles(data);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <p>Loading articles...</p>;
  }

  if (error) {
    return <p>Failed to load articles.</p>;
  }

  return (
    <>
    <Helmet>
      <title>{siteName} - Latest Blog Posts</title>
      <meta name="description" content={`Compare price of Lego Sets at Brickprices.co.uk and keep up to date with the latest news and reviews from the world of Lego`} />
      <meta name="keywords" content={`Compare,Price,Lego Set,Construction,Toy`} />
    </Helmet>
    <div className="articles-container">
      <h1>Recent Posts</h1>
      {articles.length === 0 && <p>No articles available.</p>}
      <div className="articles-list">
        {articles.map((article) => {
          // Check if we're in development mode
          const isDev = process.env.NODE_ENV === 'development';
          
          // In development, change the article link to localhost, otherwise use the production link
          const articleLink = isDev
            ? `http://localhost:3000/article/${article.slug}`
            : article.link.replace('/blog/', '/article/');

          return (
            <a
              href={articleLink}
              key={article.id}
              target="_blank"
              rel="noopener noreferrer"
              className="article"
            >
              <div className="image-container">
                {article.featured_media && (
                  <img
                    src={article._embedded?.['wp:featuredmedia']?.[0]?.source_url}
                    alt={article.title.rendered}
                    className="article-image"
                  />
                )}
                <div className="image-overlay">
                  <h2 className="overlay-title">{article.title.rendered}</h2>
                </div>
              </div>
              <h2 className="article-title">{article.title.rendered}</h2>

            </a>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default Articles;
