import React, { useEffect, useState, useMemo, useRef, memo } from 'react';
import './Footer.css';

function Footer({ onChangeConsent }) {
    const [footerCategories, setFooterCategories] = useState(null);
    const [footerError, setFooterError] = useState("");
    const [footerLoading, setFooterLoading] = useState(true);

    // Memoize the URL to prevent re-fetching on every render
    const url = useMemo(() => process.env.REACT_APP_API_URL + `/home/themes`, []);

    // Add a ref to prevent multiple fetches
    const hasFetched = useRef(false);

    const fetchData = async (url, setFooterCategories, setFooterError, setFooterLoading) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Unable to fetch data');
            }
            const data = await response.json();
            setFooterCategories(data);
        } catch (error) {
            setFooterError(error);
        } finally {
            setFooterLoading(false);
        }
    };

    useEffect(() => {
        if (hasFetched.current) {
            return; // Prevent subsequent fetches
        }
        
        const getFooterCategories = async () => {
            await fetchData(url, setFooterCategories, setFooterError, setFooterLoading);
        };

        getFooterCategories();
        hasFetched.current = true; // Set the flag to true after fetching
    }, [url]);

    if (footerLoading) {
        return <p>Loading...</p>;
    }

    if (footerError) {
        return <p>Error: {footerError.message}</p>;
    }

    return (
        <div className="footer-container">
            <p>Check out these other great sets and themes below....</p>
            <ul>
                {footerCategories && footerCategories.map((category, index) => (
                    <li key={index}><a href={`/browse/${category.category}`}>{category.category}</a></li>
                ))}
            </ul>
            <button onClick={onChangeConsent} className="change-consent-button">
                Change Cookie Consent
            </button>
        </div>
    );
}

// Memoize Footer component to prevent re-renders unless props change
export default memo(Footer);
