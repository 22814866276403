import { useMemo } from 'react';
//import useImageSrc from '../hooks/useImageSrc'; // Adjust the path as needed

function SearchResultsComponent(props) {
    //const imageSrc = useImageSrc(props.id);

    // Generate the URL for the Compare Prices button
    const u = useMemo(() => 
        `/prices/${props.name.replace(/[^a-zA-Z\d\s:]/g, '').replace(/ /g, "-")}-${props.id}`, 
        [props.name, props.id]
    );

    return (
        <div className="product-card">
            <div className="image-container">
                <img
                    className="product-image"
                    src={props.image_url}
                    alt={props.name}
                />
            </div>
        
            <h3>{props.name} ({props.id})</h3>
            <p className='price'>£{props.bestPrice}</p>
            {props.wasPrice && <p className='was-price'>Was: £{props.wasPrice}</p>}
            {props.price_drop_percentage && <p className='price-drop'>{Math.round(props.price_drop_percentage)}% cheaper today</p>}
            <button>
                <a href={u}>Compare Prices</a>
            </button>
        </div>
    );
}

export default SearchResultsComponent;
