import React, { useState, useRef, useEffect } from "react";
import SearchBar from './SearchBar';
import { FaSearch, FaFacebookF, FaTwitter, FaBars, FaTimes } from "react-icons/fa";
import SearchDropdown from './SearchDropdown';
import { Helmet } from 'react-helmet';
import './Header.css';

export default function Header(props) {
    const [results, setResults] = useState([]);
    const [input, setInput] = useState('');
    const [dropDownVisible, setDropDownVisible] = useState(true);
    const [redirect, setRedirect] = useState(true);
    const dropdownRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 810);
    const [menuOpen, setMenuOpen] = useState(false); // For hamburger menu toggle

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropDownVisible(false);
        }
    };

    const handleFocus = () => {
        if (isSmallScreen) {
            setIsFocused(true);
        }
    };

    const handleBlur = () => {
        if (isSmallScreen) {
            setIsFocused(false);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle menu on small screens
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 810;
            setIsSmallScreen(isSmall);
            if (!isSmall) {
                setIsFocused(false); // Reset focus state on resize if not small screen
                setMenuOpen(false); // Close menu when resizing to large screen
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSmallScreen]);

    return (
        <div>
            <div className='header'>
                <div className={`header-left ${isSmallScreen && isFocused ? 'hidden' : ''}`}>
                    
                    {isSmallScreen ? (
                        <>
                        <div className="hamburger-menu">
                            <button onClick={toggleMenu}>
                                {menuOpen ? <FaTimes /> : <FaBars />}
                            </button>
                            {menuOpen && (
                                <div className="menu-dropdown">
                                    <a href="/about">About</a>
                                    <a href="/articles">Blog</a>
                                </div>
                            )}
                        </div>
                        <a href="/"><img src="/assets/images/brick-prices-logo2.png" alt="Brick Prices Logo" className="logo" /></a>
                        </>
                    ) : (
                        <>
                        <a href="/"><img src="/assets/images/brick-prices-logo2.png" alt="Brick Prices Logo" className="logo" /></a>
                        <div className="nav-links">
                            <a href="/about">About</a>
                            <a href="/articles">Blog</a>

                        </div>
                        </>
                    )}
                </div>

                {/* Links and Hamburger Menu */}
                <div className={`header-right ${isSmallScreen && isFocused ? 'full' : ''}`}>


                    <div className={`social-icons ${isSmallScreen && isFocused ? 'hidden' : ''}`}>
                        <a href="https://www.facebook.com/groups/925564609568249" target="_blank" rel="noopener noreferrer">
                            <FaFacebookF />
                        </a>
                        <a href="https://x.com/brickpricecouk" target="_blank" rel="noopener noreferrer">
                            <FaTwitter />
                        </a>
                    </div>

                    <div id="search-icon">
                        <FaSearch />
                    </div>

                    <div className='stack'>
                        <SearchBar
                            setResults={setResults}
                            updateInput={setInput}
                            updateDropDownVisible={setDropDownVisible}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            input={input}
                            isFocused={isFocused}
                        />
                        {dropDownVisible && (
                            <SearchDropdown
                                results={results}
                                updateInput={setInput}
                                updateDropDownVisible={setDropDownVisible}
                                updateRedirect={setRedirect}
                                dropdownRef={dropdownRef}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
