// Sidebar.js
import { Link } from 'react-router-dom';


const Sidebar = () => {
  return (
    <div className="right-sidebar">
      <h2>Other Articles</h2>
      <ul>
        <li><Link to="/browse/daily">Daily Deals</Link></li>
        <li><Link to="/browse/weekly">Weekly Deals</Link></li>
        <li><Link to="/browse/retiring">Compare Prices of Retiring Lego Sets</Link></li>
        {/* Add more links as needed */}
      </ul>
    </div>
  );
};

export default Sidebar;
