import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/SideBar'; // Import the Sidebar component
import './ArticlePage.css';
import parse, { domToReact } from 'html-react-parser'; // Import html-react-parser
import { Helmet } from 'react-helmet';

const ArticlePage = () => {
  const { slug } = useParams();
  const [articleData, setArticleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const response = await fetch(`https://brickprices.co.uk/blog/wp-json/wp/v2/posts?slug=${slug}`);
        if (!response.ok) {
          throw new Error('Article data could not be fetched');
        }

        const data = await response.json();
        if (data.length === 0) {
          throw new Error('Article not found');
        }

        const article = data[0];
        setArticleData({
          title: article.title.rendered,
          content: article.content.rendered,
          sections: [{ content: article.content.rendered }],
        });
      } catch (err) {
        console.error('Error fetching article:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchArticleData();
  }, [slug]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error || !articleData) {
    return <p>Failed to load the article</p>;
  }

  // Function to detect and center images within the content
  const parsedContent = parse(articleData.content, {
    replace: (domNode) => {
      if (domNode.name === 'img') {
        return (
          <img
            {...domNode.attribs}
            className="centered-image" // Assign the centered-image class
            alt={domNode.attribs.alt || 'Image'}
          />
        );
      }
      // Ensure domNode has children before calling domToReact
      if (domNode.children && domNode.children.length > 0) {
        return domToReact(domNode.children);
      }
      return null; // Handle nodes without children (like self-closing tags)
    }
  });

  return (
    <>


    <Helmet>
      <title>{articleData.title} - Article on BrickPrices</title>
      <meta name="description" content={`Read this article: ${articleData.title}`} />
      <meta name="keywords" content={`Lego, ${articleData.title}, blog`} />
    </Helmet>

    <div className="article-container">
      <div className="article-content">
        <h1>{articleData.title}</h1>
        {articleData.sections.map((section, index) => (
          <div className="article-section" key={index}>
            <div className="article-content">
              {parsedContent} {/* Render the parsed and modified content */}
            </div>
          </div>
        ))}
      </div>
      <Sidebar /> {/* Sidebar is placed next to the article content */}
    </div>
    </>
  );
};

export default ArticlePage;
